/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@keyframes Floatingy {
	from {
		-webkit-transform: translate(0, 0px);
	}
	65% {
		-webkit-transform: translate(50px, 0);
	}
	to {
		-webkit-transform: translate(0, 0px);
	}
}

@keyframes Floatingx {
	from {
		-webkit-transform: translate(50px, 0px);
	}
	65% {
		-webkit-transform: translate(0, 0);
	}
	to {
		-webkit-transform: translate(50px, 0px);
	}
}

/* For some obscure reason, reactstrap spinners display the text no matter what */
.visually-hidden {
	display: none !important;
}
